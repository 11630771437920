import z from 'zod';

import { TimezoneIanaSchema } from './time';
import { convertNullToUndefined, trimmedString } from './utils';

export const GcalSettingsSchema = z.object({
  push: z.object({
    enabled: z.boolean(),
    shifts: z.boolean(),
    activities: z.boolean(),
  }),
  pull: z
    .object({
      enabled: z.boolean(),
      useSearchTerms: z.boolean(),
      searchTerms: z.array(z.string()),
    })
    .refine(
      pull => {
        if (
          pull.useSearchTerms &&
          (!pull.searchTerms?.length || pull.searchTerms[0].length === 0)
        ) {
          return false;
        }
        return true;
      },
      { message: 'Keyword is required for pulling specific events' },
    ),
});

const GcalSettingsDbSchema = GcalSettingsSchema.extend({
  pull: z.object({
    enabled: z.boolean(),
    use_search_terms: z.boolean(),
    search_terms: z.array(z.string()),
  }),
});

export type GcalSettings = z.infer<typeof GcalSettingsSchema>;

// Ensure org settings db schema is updated when org settings schema is changed
const OrganizationSettingsSchema = z.object({
  organizationId: z.number(),
  shiftPlanningEnabled: convertNullToUndefined(z.boolean()),
  schedulingEnabled: z.boolean(),
  timeOffIcalLink: convertNullToUndefined(trimmedString),
  zendeskSubdomain: convertNullToUndefined(trimmedString),
  salesforceSubdomain: convertNullToUndefined(trimmedString),
  talkdeskSubdomain: convertNullToUndefined(trimmedString),
  gcalSettings: convertNullToUndefined(GcalSettingsSchema),
  dialbotEnabled: z.boolean(),
});

// Schema is converted to snake case when stored in the database
const OrganizationSettingsDbSchema = z.object({
  id: z.number(),
  organization_id: z.number(),
  shift_planning_enabled: convertNullToUndefined(z.boolean()),
  scheduling_enabled: z.boolean(),
  time_off_ical_link: convertNullToUndefined(trimmedString),
  zendesk_subdomain: convertNullToUndefined(trimmedString),
  salesforce_subdomain: convertNullToUndefined(trimmedString),
  talkdesk_subdomain: convertNullToUndefined(trimmedString),
  gcal_settings: GcalSettingsDbSchema.nullable(),
  dialbot_enabled: z.boolean(),
});

const PersistedOrganizationSettingsSchema = OrganizationSettingsSchema.merge(
  z.object({ id: z.number() }),
);

export const PersistedOrganizationSettingsAndTimezoneSchema =
  PersistedOrganizationSettingsSchema.merge(
    z.object({
      timezone: TimezoneIanaSchema,
    }),
  );

export type OrganizationSettings = z.infer<typeof OrganizationSettingsSchema>;
/**
 * Organization settings in the database are stored in snake_case.
 */
export type OrganizationSettingsDB = z.infer<typeof OrganizationSettingsDbSchema>;
export type PersistedOrganizationSettingsAndTimezone = z.infer<
  typeof PersistedOrganizationSettingsAndTimezoneSchema
>;

export const GetOrgSettingsRespSchema = PersistedOrganizationSettingsAndTimezoneSchema;
export type GetOrgSettingsResp = z.infer<typeof PersistedOrganizationSettingsAndTimezoneSchema>;

export const UpdateOrgSettingsReqSchema = OrganizationSettingsSchema.partial();
export type UpdateOrgSettingsReq = z.infer<typeof UpdateOrgSettingsReqSchema>;

export const UpdateOrgSettingsRespSchema = PersistedOrganizationSettingsSchema;
