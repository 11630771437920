import {
  BlockIdSchema,
  IntervalSchema,
  SurferIdSchema,
  ZonedAvailability,
  ZonedAvailabilitySchema,
  dateString,
  pagedResultsSchema,
} from 'shared-types';
import { z } from 'zod';

import { ScheduledTaskSchema } from './activity';
import { ConflictSchema } from './conflict';
import { StreamIdSchema, SurferId } from './ids';
import { PersistedPlanSchema } from './plan';
import {
  AssignedShiftBlockSchema,
  Block,
  OriginalFillBlockSchema,
  TimeInLieuBlockSchema,
  TimeOffBlockSchema,
} from './schedule';
import { PersistedShiftTemplateSchema } from './shift-templates';
import { UtcIntervalSchema, ZonedIntervalSchema } from './timezone';

export const ZonedScheduledTaskSchema = ScheduledTaskSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
  }),
);
export type ZonedScheduledTask = z.infer<typeof ZonedScheduledTaskSchema>;

export const ZonedOriginalFillBlockSchema = OriginalFillBlockSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
    tasks: z.array(ZonedScheduledTaskSchema).optional(),
  }),
);

export const ZonedAssignedShiftBlockSchema = AssignedShiftBlockSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
    tasks: z.array(ZonedScheduledTaskSchema).optional(),
    originalFillBlock: ZonedOriginalFillBlockSchema.optional(),
  }),
);
export const ZonedTimeOffBlockSchema = TimeOffBlockSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
    originalFillBlock: ZonedOriginalFillBlockSchema.optional(),
  }),
);
export const ZonedTimeInLieuBlockSchema = TimeInLieuBlockSchema.merge(
  z.object({
    interval: ZonedIntervalSchema,
    originalFillBlock: ZonedOriginalFillBlockSchema.optional(),
  }),
);

export const ZonedBlockSchema = z.discriminatedUnion('type', [
  ZonedAssignedShiftBlockSchema,
  ZonedTimeOffBlockSchema,
  ZonedTimeInLieuBlockSchema,
]);

export type ZonedBlock = z.infer<typeof ZonedBlockSchema>;
export type ZonedAssignedShiftBlock = z.infer<typeof ZonedAssignedShiftBlockSchema>;
export type ZonedTimeOffBlock = z.infer<typeof ZonedTimeOffBlockSchema>;
export type ZonedTimeInLieuBlock = z.infer<typeof ZonedTimeInLieuBlockSchema>;

export const isShiftBlock = (block: ZonedBlock): block is ZonedAssignedShiftBlock => {
  return block.type === 'block-assigned-shift';
};
export const isTimeOffBlock = (block: ZonedBlock): block is ZonedTimeOffBlock => {
  return block.type === 'block-time-off';
};
export const isTimeInLieuBlock = (block: ZonedBlock): block is ZonedTimeInLieuBlock => {
  return block.type === 'block-time-in-lieu';
};
export const isAvailabilityBlock = (
  block?: EnrichedBlockOutput | ZonedAvailability,
): block is ZonedAvailability => {
  return !!block && block.blockType === 'availability-block';
};
export const isEnrichedBlock = (
  block?: EnrichedBlockOutput | ZonedAvailability,
): block is EnrichedBlockOutput => {
  return !!block && block.blockType === 'enriched-block';
};

export const isFillShiftBlock = (block: ZonedBlock): boolean => {
  return isShiftBlock(block) && block.conflictType === 'fill';
};

const UtcScheduledTaskSchema = ScheduledTaskSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
  }),
);

export const UtcOriginalFillBlockSchema = OriginalFillBlockSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
    tasks: z.array(UtcScheduledTaskSchema).optional(),
  }),
);

const UtcAssignedShiftBlockSchema = AssignedShiftBlockSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
    tasks: z.array(UtcScheduledTaskSchema).optional(),
    originalFillBlock: UtcOriginalFillBlockSchema.optional(),
  }),
);
const UtcTimeOffBlockSchema = TimeOffBlockSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
    originalFillBlock: UtcOriginalFillBlockSchema.optional(),
  }),
);
const UtcTimeInLieuBlockSchema = TimeInLieuBlockSchema.merge(
  z.object({
    interval: UtcIntervalSchema,
    originalFillBlock: UtcOriginalFillBlockSchema.optional(),
  }),
);

export const UtcBlockSchema = z.discriminatedUnion('type', [
  UtcAssignedShiftBlockSchema,
  UtcTimeOffBlockSchema,
  UtcTimeInLieuBlockSchema,
]);

export type UtcBlock = z.infer<typeof UtcBlockSchema>;

// This is required as the schema self references
const EnrichedUtcBlockWithoutConflictsSchema = z.object({
  block: UtcBlockSchema,
  shiftTemplate: PersistedShiftTemplateSchema.optional(),
  plan: PersistedPlanSchema.optional(),
});
type EnrichedUtcBlockInput = z.input<typeof EnrichedUtcBlockWithoutConflictsSchema> & {
  conflicts: EnrichedUtcConflictInput[];
};
type EnrichedUtcBlockOutput = z.output<typeof EnrichedUtcBlockWithoutConflictsSchema> & {
  conflicts: EnrichedUtcConflictOutput[];
};

export const EnrichedUtcBlockSchema: z.ZodType<EnrichedUtcBlockOutput, any, EnrichedUtcBlockInput> =
  EnrichedUtcBlockWithoutConflictsSchema.extend({
    conflicts: z.lazy(() => EnrichedUtcConflictSchema.array()),
  });

export const EnrichedUtcConflictSchema = ConflictSchema.merge(
  z.object({ blocks: EnrichedUtcBlockSchema.array() }),
);
type EnrichedUtcConflictInput = z.input<typeof EnrichedUtcConflictSchema>;
type EnrichedUtcConflictOutput = z.output<typeof EnrichedUtcConflictSchema>;
export type EnrichedUtcConflict = EnrichedUtcConflictOutput;

// This is required as the schema self references
const EnrichedBlockWithoutConflictsSchema = z.object({
  block: ZonedBlockSchema,
  shiftTemplate: PersistedShiftTemplateSchema.optional(),
  plan: PersistedPlanSchema.optional(),
  blockType: z.literal('enriched-block'),
});

type EnrichedBlockInput = z.input<typeof EnrichedBlockWithoutConflictsSchema> & {
  conflicts: EnrichedConflictInput[];
};
type EnrichedBlockOutput = z.output<typeof EnrichedBlockWithoutConflictsSchema> & {
  conflicts: EnrichedConflictOutput[];
};
export type EnrichedBlock = EnrichedBlockOutput;

export const EnrichedBlockSchema: z.ZodType<EnrichedBlockOutput, any, EnrichedBlockInput> =
  EnrichedBlockWithoutConflictsSchema.extend({
    conflicts: z.lazy(() => EnrichedConflictSchema.array()),
  });

export type EnrichedUtcBlocksBySurferId = { [key: SurferId]: EnrichedUtcBlock[] };
export type EnrichedBlocksBySurferId = { [key: SurferId]: EnrichedBlock[] };
export type BlocksBySurferId = { [key: SurferId]: Block[] };

export const EnrichedConflictSchema = ConflictSchema.merge(
  z.object({ blocks: EnrichedBlockSchema.array() }),
);
type EnrichedConflictInput = z.input<typeof EnrichedConflictSchema>;
type EnrichedConflictOutput = z.output<typeof EnrichedConflictSchema>;
export type EnrichedConflict = EnrichedConflictOutput;

export const EditShiftBlockSchema = z.object({
  data: AssignedShiftBlockSchema,
  selectedBlock: EnrichedBlockSchema,
});

export type EditShiftBlock = z.infer<typeof EditShiftBlockSchema>;

export const CreateShiftBlockSchema = z.object({
  data: AssignedShiftBlockSchema,
  selectedBlock: ZonedAvailabilitySchema.nullish(),
});

export type CreateShiftBlock = z.infer<typeof CreateShiftBlockSchema>;

export type SurferHours = { [key: SurferId]: number };
export type SurferHoursByDay = {
  [key: string]: SurferHours;
};
/** new Shift block endpoints repsonse **/

const RelaxedUtcScheduledTaskSchema = ScheduledTaskSchema.merge(
  z.object({
    interval: IntervalSchema,
  }),
);

export const RelaxedUtcOriginalFillBlockSchema = OriginalFillBlockSchema.merge(
  z.object({
    interval: IntervalSchema,
    tasks: z.array(RelaxedUtcScheduledTaskSchema).optional(),
  }),
);

const RelaxedUtcAssignedBlockSchema = AssignedShiftBlockSchema.merge(
  z.object({
    interval: IntervalSchema,
    tasks: z.array(RelaxedUtcScheduledTaskSchema).optional(),
    originalFillBlock: RelaxedUtcOriginalFillBlockSchema.optional(),
  }),
);

export const RelaxedUtcTimeOffBlockSchema = TimeOffBlockSchema.merge(
  z.object({
    interval: IntervalSchema,
    originalFillBlock: RelaxedUtcOriginalFillBlockSchema.optional(),
  }),
);

export const RelaxedUtcTimeInLieuBlockSchema = TimeInLieuBlockSchema.merge(
  z.object({
    interval: IntervalSchema,
    originalFillBlock: RelaxedUtcOriginalFillBlockSchema.optional(),
  }),
);

export const RelaxedUtcBlockSchema = z.discriminatedUnion('type', [
  RelaxedUtcAssignedBlockSchema,
  RelaxedUtcTimeOffBlockSchema,
  RelaxedUtcTimeInLieuBlockSchema,
]);

const RelaxedEnrichedUtcBlockWithoutConflictsSchema = z.object({
  block: RelaxedUtcBlockSchema,
  shiftTemplate: PersistedShiftTemplateSchema.optional(),
  plan: PersistedPlanSchema.optional(),
});

type RelaxedEnrichedUtcBlockInput = z.input<
  typeof RelaxedEnrichedUtcBlockWithoutConflictsSchema
> & {
  conflicts: RelaxedEnrichedUtcConflictInput[];
};
type RelaxedEnrichedUtcBlockOutput = z.output<
  typeof RelaxedEnrichedUtcBlockWithoutConflictsSchema
> & {
  conflicts: RelaxedEnrichedUtcConflictOutput[];
};
export type EnrichedUtcBlock = EnrichedUtcBlockOutput;

export const RelaxedEnrichedUtcBlockSchema: z.ZodType<
  RelaxedEnrichedUtcBlockOutput,
  any,
  RelaxedEnrichedUtcBlockInput
> = RelaxedEnrichedUtcBlockWithoutConflictsSchema.extend({
  conflicts: z.lazy(() => RelaxedEnrichedUtcConflictSchema.array()),
});

export const RelaxedEnrichedUtcConflictSchema = ConflictSchema.merge(
  z.object({ blocks: RelaxedEnrichedUtcBlockSchema.array() }),
);
type RelaxedEnrichedUtcConflictInput = z.input<typeof RelaxedEnrichedUtcConflictSchema>;
type RelaxedEnrichedUtcConflictOutput = z.output<typeof RelaxedEnrichedUtcConflictSchema>;

const EnrichedUtcBlockResponseSchema = z.object({
  block: RelaxedUtcBlockSchema,
  shiftTemplate: PersistedShiftTemplateSchema.optional(),
  plan: PersistedPlanSchema.optional(),
  conflicts: RelaxedEnrichedUtcConflictSchema.array(),
});
export type EnrichedUtcBlockResponse = z.infer<typeof EnrichedUtcBlockResponseSchema>;

export const GetShiftBlockRespSchema = pagedResultsSchema(EnrichedUtcBlockResponseSchema);

export const EditConflictModeSchema = z.union([z.literal('IGNORE'), z.literal('REVERT')]);

export const EditShiftConflictReqSchema = z.object({
  mode: EditConflictModeSchema,
  blockId: BlockIdSchema,
  startDate: dateString,
  endDate: dateString,
  surferId: SurferIdSchema,
});

export const AutoshiftErrorKindSchema = z.enum([
  'NO_FORECAST',
  'NO_WORKING_WINDOW',
  'UNABLE_TO_ADD',
  'NO_SURFER_HOURS',
  'WEEKLY_MINIMUM_MET',
  'BUFFER_TOO_LARGE',
]);
export type AutoshiftErrorKind = z.infer<typeof AutoshiftErrorKindSchema>;

const AutofillResultOkSchema = z.object({
  done: z.literal(true),
});
export type AutofillResultOk = z.infer<typeof AutofillResultOkSchema>;

const AutofillResultErrorSchema = z.object({
  done: z.literal(false),
  errors: z.array(AutoshiftErrorKindSchema),
});
export type AutofillResultError = z.infer<typeof AutofillResultErrorSchema>;

const AutofillResultSchema = z.discriminatedUnion('done', [
  AutofillResultOkSchema,
  AutofillResultErrorSchema,
]);
export type AutofillResult = z.infer<typeof AutofillResultSchema>;

/**
 * Request for the /autofill endpoint.
 */
export const AutofillReqSchema = z.object({
  /**
   * What interval of the schedule we should try to autofill with shifts.
   */
  interval: IntervalSchema,
  /**
   * Which surfers we should try to autofill the schedule for.
   */
  surfers: SurferIdSchema.array(),
  /**
   * Indicates which staffing requirements to use for scheduling the shifts.
   *
   * If `undefined`, it will use the staffing requirements from all the existing streams.
   */
  streamIds: StreamIdSchema.array().optional(),
});
export type AutofillReq = z.infer<typeof AutofillReqSchema>;

export const AutofillRespSchema = z.record(SurferIdSchema, AutofillResultSchema);
export type AutofillResp = z.infer<typeof AutofillRespSchema>;
