import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tableAnatomy.keys,
);

const baseStyle = definePartsStyle({
  table: {
    fontVariantNumeric: 'inherit',
    borderCollapse: 'collapse',
    width: 'full',
    fontFamily: 'var(--dt-font-family-body)',
  },
  th: {
    fontStyle: 'normal',
    fontWeight: 'medium',
    textTransform: 'none',
    textAlign: 'start',
    letterSpacing: 'normal',
  },
  td: {
    fontWeight: 'normal',
    textAlign: 'start',
  },
  caption: {
    mt: 4,
    textAlign: 'center',
    fontWeight: 'medium',
  },
});

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
});

const variantSimple = definePartsStyle({
  th: {
    color: 'var(--color-foreground-tertiary, #555)',
    borderBottom: '1px',
    borderColor: 'var(--dt-color-border-subtle)',
    ...numericStyles,
  },
  td: {
    color: 'var(--dt-color-foreground-secondary)',
    border: 'none',
    ...numericStyles,
  },
  caption: {
    color: 'var(--color-foreground-tertiary, #555)',
  },
  tfoot: {
    tr: {
      '&:last-of-type': {
        th: { borderBottomWidth: 0 },
      },
    },
  },
});

const variants = {
  simple: variantSimple,
  unstyled: defineStyle({}),
};

const sizes = {
  md: definePartsStyle({
    th: {
      px: '5',
      py: '4',
      lineHeight: 'heading',
      fontSize: 'f2',
      height: '48px',
    },
    td: {
      px: '5',
      py: '3',
      lineHeight: 'heading',
      fontSize: 'f2',
      height: '56px',
    },
    caption: {
      px: '6',
      py: '2',
      fontSize: 'f1',
    },
  }),
};

export const tableTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
  defaultProps: {
    variant: 'simple',
    size: 'md',
  },
});
