// taken from https://nodatime.org/TimeZones
// and https://timezonedb.com/time-zones
export const rawTimezoneCountryCodeMap: Record<string, string> = {
  'Africa/Abidjan': 'CI',
  'Africa/Algiers': 'DZ',
  'Africa/Bissau': 'GW',
  'Africa/Cairo': 'EG',
  'Africa/Casablanca': 'MA',
  'Africa/Ceuta': 'ES',
  'Africa/Johannesburg': 'ZA',
  'Africa/Juba': 'SS',
  'Africa/Khartoum': 'SD',
  'Africa/Lagos': 'NG',
  'Africa/Maputo': 'MZ',
  'Africa/Monrovia': 'LR',
  'Africa/Nairobi': 'KE',
  'Africa/Ndjamena': 'TD',
  'Africa/Sao_Tome': 'ST',
  'Africa/Tripoli': 'LY',
  'Africa/Tunis': 'TN',
  'Africa/Windhoek': 'NA',
  'America/Adak': 'US',
  'America/Anchorage': 'US',
  'America/Araguaina': 'BR',
  'America/Argentina/Buenos_Aires': 'AR',
  'America/Argentina/Catamarca': 'AR',
  'America/Argentina/Cordoba': 'AR',
  'America/Argentina/Jujuy': 'AR',
  'America/Argentina/La_Rioja': 'AR',
  'America/Argentina/Mendoza': 'AR',
  'America/Argentina/Rio_Gallegos': 'AR',
  'America/Argentina/Salta': 'AR',
  'America/Argentina/San_Juan': 'AR',
  'America/Argentina/San_Luis': 'AR',
  'America/Argentina/Tucuman': 'AR',
  'America/Argentina/Ushuaia': 'AR',
  'America/Asuncion': 'PY',
  'America/Bahia': 'BR',
  'America/Bahia_Banderas': 'MX',
  'America/Barbados': 'BB',
  'America/Belem': 'BR',
  'America/Belize': 'BZ',
  'America/Boa_Vista': 'BR',
  'America/Bogota': 'CO',
  'America/Boise': 'US',
  'America/Cambridge_Bay': 'CA',
  'America/Campo_Grande': 'BR',
  'America/Cancun': 'MX',
  'America/Caracas': 'VE',
  'America/Cayenne': 'GF',
  'America/Chicago': 'US',
  'America/Chihuahua': 'MX',
  'America/Ciudad_Juarez': 'MX',
  'America/Costa_Rica': 'CR',
  'America/Cuiaba': 'BR',
  'America/Danmarkshavn': 'GL',
  'America/Dawson': 'CA',
  'America/Dawson_Creek': 'CA',
  'America/Denver': 'US',
  'America/Detroit': 'US',
  'America/Edmonton': 'CA',
  'America/Eirunepe': 'BR',
  'America/El_Salvador': 'SV',
  'America/Fort_Nelson': 'CA',
  'America/Fortaleza': 'BR',
  'America/Glace_Bay': 'CA',
  'America/Goose_Bay': 'CA',
  'America/Grand_Turk': 'TC',
  'America/Guatemala': 'GT',
  'America/Guayaquil': 'EC',
  'America/Guyana': 'GY',
  'America/Halifax': 'CA',
  'America/Havana': 'CU',
  'America/Hermosillo': 'MX',
  'America/Indiana/Indianapolis': 'US',
  'America/Indiana/Knox': 'US',
  'America/Indiana/Marengo': 'US',
  'America/Indiana/Petersburg': 'US',
  'America/Indiana/Tell_City': 'US',
  'America/Indiana/Vevay': 'US',
  'America/Indiana/Vincennes': 'US',
  'America/Indiana/Winamac': 'US',
  'America/Inuvik': 'CA',
  'America/Iqaluit': 'CA',
  'America/Jamaica': 'JM',
  'America/Juneau': 'US',
  'America/Kentucky/Louisville': 'US',
  'America/Kentucky/Monticello': 'US',
  'America/La_Paz': 'BO',
  'America/Lima': 'PE',
  'America/Los_Angeles': 'US',
  'America/Maceio': 'BR',
  'America/Managua': 'NI',
  'America/Manaus': 'BR',
  'America/Martinique': 'MQ',
  'America/Matamoros': 'MX',
  'America/Mazatlan': 'MX',
  'America/Menominee': 'US',
  'America/Merida': 'MX',
  'America/Metlakatla': 'US',
  'America/Mexico_City': 'MX',
  'America/Miquelon': 'PM',
  'America/Moncton': 'CA',
  'America/Monterrey': 'MX',
  'America/Montevideo': 'UY',
  'America/New_York': 'US',
  'America/Nome': 'US',
  'America/Noronha': 'BR',
  'America/North_Dakota/Beulah': 'US',
  'America/North_Dakota/Center': 'US',
  'America/North_Dakota/New_Salem': 'US',
  'America/Nuuk': 'GL',
  'America/Ojinaga': 'MX',
  'America/Panama': 'PA',
  'America/Paramaribo': 'SR',
  'America/Phoenix': 'US',
  'America/Port-au-Prince': 'HT',
  'America/Porto_Velho': 'BR',
  'America/Puerto_Rico': 'PR',
  'America/Punta_Arenas': 'CL',
  'America/Rankin_Inlet': 'CA',
  'America/Recife': 'BR',
  'America/Regina': 'CA',
  'America/Resolute': 'CA',
  'America/Rio_Branco': 'BR',
  'America/Santarem': 'BR',
  'America/Santiago': 'CL',
  'America/Santo_Domingo': 'DO',
  'America/Sao_Paulo': 'BR',
  'America/Scoresbysund': 'GL',
  'America/Sitka': 'US',
  'America/St_Johns': 'CA',
  'America/Swift_Current': 'CA',
  'America/Tegucigalpa': 'HN',
  'America/Thule': 'GL',
  'America/Tijuana': 'MX',
  'America/Toronto': 'CA',
  'America/Vancouver': 'CA',
  'America/Whitehorse': 'CA',
  'America/Winnipeg': 'CA',
  'America/Yakutat': 'US',
  'America/Yellowknife': 'CA',
  'Antarctica/Macquarie': 'AU',
  'Asia/Almaty': 'KZ',
  'Asia/Amman': 'JO',
  'Asia/Anadyr': 'RU',
  'Asia/Aqtau': 'KZ',
  'Asia/Aqtobe': 'KZ',
  'Asia/Ashgabat': 'TM',
  'Asia/Atyrau': 'KZ',
  'Asia/Baghdad': 'IQ',
  'Asia/Baku': 'AZ',
  'Asia/Bangkok': 'TH',
  'Asia/Barnaul': 'RU',
  'Asia/Beirut': 'LB',
  'Asia/Bishkek': 'KG',
  'Asia/Chita': 'RU',
  'Asia/Choibalsan': 'MN',
  'Asia/Colombo': 'LK',
  'Asia/Damascus': 'SY',
  'Asia/Dhaka': 'BD',
  'Asia/Dili': 'TL',
  'Asia/Dubai': 'AE',
  'Asia/Dushanbe': 'TJ',
  'Asia/Famagusta': 'CY',
  'Asia/Gaza': 'PS',
  'Asia/Hebron': 'PS',
  'Asia/Ho_Chi_Minh': 'VN',
  'Asia/Hong_Kong': 'HK',
  'Asia/Hovd': 'MN',
  'Asia/Irkutsk': 'RU',
  'Asia/Jakarta': 'ID',
  'Asia/Jayapura': 'ID',
  'Asia/Jerusalem': 'IL',
  'Asia/Kabul': 'AF',
  'Asia/Kamchatka': 'RU',
  'Asia/Karachi': 'PK',
  'Asia/Kathmandu': 'NP',
  'Asia/Khandyga': 'RU',
  'Asia/Kolkata': 'IN',
  'Asia/Krasnoyarsk': 'RU',
  'Asia/Kuching': 'MY',
  'Asia/Macau': 'MO',
  'Asia/Magadan': 'RU',
  'Asia/Makassar': 'ID',
  'Asia/Manila': 'PH',
  'Asia/Nicosia': 'CY',
  'Asia/Novokuznetsk': 'RU',
  'Asia/Novosibirsk': 'RU',
  'Asia/Omsk': 'RU',
  'Asia/Oral': 'KZ',
  'Asia/Pontianak': 'ID',
  'Asia/Pyongyang': 'KP',
  'Asia/Qatar': 'QA',
  'Asia/Qostanay': 'KZ',
  'Asia/Qyzylorda': 'KZ',
  'Asia/Riyadh': 'SA',
  'Asia/Sakhalin': 'RU',
  'Asia/Samarkand': 'UZ',
  'Asia/Seoul': 'KR',
  'Asia/Shanghai': 'CN',
  'Asia/Singapore': 'SG',
  'Asia/Srednekolymsk': 'RU',
  'Asia/Taipei': 'TW',
  'Asia/Tashkent': 'UZ',
  'Asia/Tbilisi': 'GE',
  'Asia/Tehran': 'IR',
  'Asia/Thimphu': 'BT',
  'Asia/Tokyo': 'JP',
  'Asia/Tomsk': 'RU',
  'Asia/Ulaanbaatar': 'MN',
  'Asia/Urumqi': 'CN',
  'Asia/Ust-Nera': 'RU',
  'Asia/Vladivostok': 'RU',
  'Asia/Yakutsk': 'RU',
  'Asia/Yangon': 'MM',
  'Asia/Yekaterinburg': 'RU',
  'Asia/Yerevan': 'AM',
  'Atlantic/Azores': 'PT',
  'Atlantic/Bermuda': 'BM',
  'Atlantic/Canary': 'ES',
  'Atlantic/Cape_Verde': 'CV',
  'Atlantic/Faroe': 'FO',
  'Atlantic/Madeira': 'PT',
  'Atlantic/South_Georgia': 'GS',
  'Atlantic/Stanley': 'FK',
  'Australia/Adelaide': 'AU',
  'Australia/Brisbane': 'AU',
  'Australia/Broken_Hill': 'AU',
  'Australia/Darwin': 'AU',
  'Australia/Eucla': 'AU',
  'Australia/Hobart': 'AU',
  'Australia/Lindeman': 'AU',
  'Australia/Lord_Howe': 'AU',
  'Australia/Melbourne': 'AU',
  'Australia/Perth': 'AU',
  'Australia/Sydney': 'AU',
  'Europe/Andorra': 'AD',
  'Europe/Astrakhan': 'RU',
  'Europe/Athens': 'GR',
  'Europe/Belgrade': 'RS',
  'Europe/Berlin': 'DE',
  'Europe/Brussels': 'BE',
  'Europe/Bucharest': 'RO',
  'Europe/Budapest': 'HU',
  'Europe/Chisinau': 'MD',
  'Europe/Dublin': 'IE',
  'Europe/Gibraltar': 'GI',
  'Europe/Helsinki': 'FI',
  'Europe/Istanbul': 'TR',
  'Europe/Kaliningrad': 'RU',
  'Europe/Kirov': 'RU',
  'Europe/Kyiv': 'UA',
  'Europe/Lisbon': 'PT',
  'Europe/London': 'GB',
  'Europe/Madrid': 'ES',
  'Europe/Malta': 'MT',
  'Europe/Minsk': 'BY',
  'Europe/Moscow': 'RU',
  'Europe/Paris': 'FR',
  'Europe/Prague': 'CZ',
  'Europe/Riga': 'LV',
  'Europe/Rome': 'IT',
  'Europe/Samara': 'RU',
  'Europe/Saratov': 'RU',
  'Europe/Simferopol': 'UA',
  'Europe/Sofia': 'BG',
  'Europe/Tallinn': 'EE',
  'Europe/Tirane': 'AL',
  'Europe/Ulyanovsk': 'RU',
  'Europe/Vienna': 'AT',
  'Europe/Vilnius': 'LT',
  'Europe/Volgograd': 'RU',
  'Europe/Warsaw': 'PL',
  'Europe/Zurich': 'CH',
  'Indian/Chagos': 'IO',
  'Indian/Maldives': 'MV',
  'Indian/Mauritius': 'MU',
  'Pacific/Apia': 'WS',
  'Pacific/Auckland': 'NZ',
  'Pacific/Bougainville': 'PG',
  'Pacific/Chatham': 'NZ',
  'Pacific/Easter': 'CL',
  'Pacific/Efate': 'VU',
  'Pacific/Fakaofo': 'TK',
  'Pacific/Fiji': 'FJ',
  'Pacific/Galapagos': 'EC',
  'Pacific/Gambier': 'PF',
  'Pacific/Guadalcanal': 'SB',
  'Pacific/Guam': 'GU',
  'Pacific/Honolulu': 'US',
  'Pacific/Kanton': 'KI',
  'Pacific/Kiritimati': 'KI',
  'Pacific/Kosrae': 'FM',
  'Pacific/Kwajalein': 'MH',
  'Pacific/Marquesas': 'PF',
  'Pacific/Nauru': 'NR',
  'Pacific/Niue': 'NU',
  'Pacific/Norfolk': 'NF',
  'Pacific/Noumea': 'NC',
  'Pacific/Pago_Pago': 'AS',
  'Pacific/Palau': 'PW',
  'Pacific/Pitcairn': 'PN',
  'Pacific/Port_Moresby': 'PG',
  'Pacific/Rarotonga': 'CK',
  'Pacific/Tahiti': 'PF',
  'Pacific/Tarawa': 'KI',
  'Pacific/Tongatapu': 'TO',
  'Africa/Luanda': 'AO',
  'America/Anguilla': 'AI',
  'America/Antigua': 'AG',
  'America/Aruba': 'AW',
  'America/Nassau': 'BS',
  'Asia/Bahrain': 'BH',
  'Africa/Porto-Novo': 'BJ',
  'Europe/Sarajevo': 'BA',
  'Africa/Gaborone': 'BW',
  'Asia/Brunei': 'BN',
  'Africa/Ouagadougou': 'BF',
  'Africa/Bujumbura': 'BI',
  'Asia/Phnom_Penh': 'KH',
  'Africa/Douala': 'CM',
  'America/Atikokan': 'CA',
  'America/Blanc-Sablon': 'CA',
  'America/Creston': 'CA',
  'America/Cayman': 'KY',
  'Africa/Bangui': 'CF',
  'Indian/Christmas': 'CX',
  'Indian/Cocos': 'CC',
  'Indian/Comoro': 'KM',
  'Africa/Brazzaville': 'CG',
  'Africa/Kinshasa': 'CD',
  'Africa/Lubumbashi': 'CD',
  'Europe/Zagreb': 'HR',
  'America/Curacao': 'CW',
  'Europe/Copenhagen': 'DK',
  'Africa/Djibouti': 'DJ',
  'America/Dominica': 'DM',
  'Africa/Malabo': 'GQ',
  'Africa/Asmara': 'ER',
  'Africa/Addis_Ababa': 'ET',
  'Indian/Kerguelen': 'TF',
  'Africa/Libreville': 'GA',
  'Africa/Banjul': 'GM',
  'Europe/Busingen': 'DE',
  'Africa/Accra': 'GH',
  'America/Grenada': 'GD',
  'America/Guadeloupe': 'GP',
  'Europe/Guernsey': 'GG',
  'Africa/Conakry': 'GN',
  'Europe/Vatican': 'VA',
  'Atlantic/Reykjavik': 'IS',
  'Europe/Isle_of_Man': 'IM',
  'Europe/Jersey': 'JE',
  'Asia/Kuwait': 'KW',
  'Asia/Vientiane': 'LA',
  'Africa/Maseru': 'LS',
  'Europe/Vaduz': 'LI',
  'Europe/Luxembourg': 'LU',
  'Europe/Skopje': 'MK',
  'Indian/Antananarivo': 'MG',
  'Africa/Blantyre': 'MW',
  'Asia/Kuala_Lumpur': 'MY',
  'Africa/Bamako': 'ML',
  'Pacific/Majuro': 'MH',
  'Africa/Nouakchott': 'MR',
  'Indian/Mayotte': 'YT',
  'Pacific/Chuuk': 'FM',
  'Pacific/Pohnpei': 'FM',
  'Europe/Monaco': 'MC',
  'Europe/Podgorica': 'ME',
  'America/Montserrat': 'MS',
  'Europe/Amsterdam': 'NL',
  'Africa/Niamey': 'NE',
  'Pacific/Saipan': 'MP',
  'Europe/Oslo': 'NO',
  'Asia/Muscat': 'OM',
  'Africa/Kigali': 'RW',
  'Indian/Reunion': 'RE',
  'America/St_Barthelemy': 'BL',
  'Atlantic/St_Helena': 'SH',
  'America/St_Kitts': 'KN',
  'America/St_Lucia': 'LC',
  'America/Marigot': 'MF',
  'America/St_Vincent': 'VC',
  'Europe/San_Marino': 'SM',
  'Africa/Dakar': 'SN',
  'Indian/Mahe': 'SC',
  'Africa/Freetown': 'SL',
  'America/Lower_Princes': 'SX',
  'Europe/Bratislava': 'SK',
  'Europe/Ljubljana': 'SI',
  'Africa/Mogadishu': 'SO',
  'Arctic/Longyearbyen': 'SJ',
  'Africa/Mbabane': 'SZ',
  'Europe/Stockholm': 'SE',
  'Africa/Dar_es_Salaam': 'TZ',
  'Africa/Lome': 'TG',
  'America/Port_of_Spain': 'TT',
  'Pacific/Funafuti': 'TV',
  'Africa/Kampala': 'UG',
  'Pacific/Midway': 'UM',
  'Pacific/Wake': 'UM',
  'America/Tortola': 'VG',
  'America/St_Thomas': 'VI',
  'Pacific/Wallis': 'WF',
  'Asia/Aden': 'YE',
  'Africa/Lusaka': 'ZM',
  'Africa/Harare': 'ZW',
  'Europe/Mariehamn': 'AX',
  'Etc/GMT+12': 'ZZ',
  'Etc/GMT+11': 'ZZ',
  'Etc/GMT+10': 'ZZ',
  'Etc/GMT+9': 'ZZ',
  'Etc/GMT+8': 'ZZ',
  'Etc/GMT+7': 'ZZ',
  'Etc/GMT+6': 'ZZ',
  'Etc/GMT+5': 'ZZ',
  'Etc/GMT+4': 'ZZ',
  'Etc/GMT+3': 'ZZ',
  'Etc/GMT+2': 'ZZ',
  'Etc/GMT+1': 'ZZ',
  'Etc/GMT-1': 'ZZ',
  'Etc/GMT-2': 'ZZ',
  'Etc/GMT-3': 'ZZ',
  'Etc/GMT-4': 'ZZ',
  'Etc/GMT-5': 'ZZ',
  'Etc/GMT-6': 'ZZ',
  'Etc/GMT-7': 'ZZ',
  'Etc/GMT-8': 'ZZ',
  'Etc/GMT-9': 'ZZ',
  'Etc/GMT-10': 'ZZ',
  'Etc/GMT-11': 'ZZ',
  'Etc/GMT-12': 'ZZ',
  'Etc/GMT-13': 'ZZ',
  'Etc/GMT-14': 'ZZ',
};

// derived from https://data.iana.org/time-zones/tzdb-2021a/backward
export const backwardsCompatibleTimezones: { [key: string]: string } = {
  'Africa/Asmera': 'Africa/Nairobi',
  'Africa/Timbuktu': 'Africa/Abidjan',
  'America/Argentina/ComodRivadavia': 'America/Argentina/Catamarca',
  'America/Atka': 'America/Adak',
  'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'America/Catamarca': 'America/Argentina/Catamarca',
  'America/Coral_Harbour': 'America/Atikokan',
  'America/Cordoba': 'America/Argentina/Cordoba',
  'America/Ensenada': 'America/Tijuana',
  'America/Fort_Wayne': 'America/Indiana/Indianapolis',
  'America/Godthab': 'America/Nuuk',
  'America/Indianapolis': 'America/Indiana/Indianapolis',
  'America/Jujuy': 'America/Argentina/Jujuy',
  'America/Knox_IN': 'America/Indiana/Knox',
  'America/Louisville': 'America/Kentucky/Louisville',
  'America/Mendoza': 'America/Argentina/Mendoza',
  'America/Montreal': 'America/Toronto',
  'America/Porto_Acre': 'America/Rio_Branco',
  'America/Rosario': 'America/Argentina/Cordoba',
  'America/Santa_Isabel': 'America/Tijuana',
  'America/Shiprock': 'America/Denver',
  'America/Virgin': 'America/Port_of_Spain',
  'Antarctica/South_Pole': 'Pacific/Auckland',
  'Asia/Ashkhabad': 'Asia/Ashgabat',
  'Asia/Calcutta': 'Asia/Kolkata',
  'Asia/Chongqing': 'Asia/Shanghai',
  'Asia/Chungking': 'Asia/Shanghai',
  'Asia/Dacca': 'Asia/Dhaka',
  'Asia/Harbin': 'Asia/Shanghai',
  'Asia/Kashgar': 'Asia/Urumqi',
  'Asia/Katmandu': 'Asia/Kathmandu',
  'Asia/Macao': 'Asia/Macau',
  'Asia/Rangoon': 'Asia/Yangon',
  'Asia/Saigon': 'Asia/Ho_Chi_Minh',
  'Asia/Tel_Aviv': 'Asia/Jerusalem',
  'Asia/Thimbu': 'Asia/Thimphu',
  'Asia/Ujung_Pandang': 'Asia/Makassar',
  'Asia/Ulan_Bator': 'Asia/Ulaanbaatar',
  'Atlantic/Faeroe': 'Atlantic/Faroe',
  'Atlantic/Jan_Mayen': 'Europe/Oslo',
  'Australia/ACT': 'Australia/Sydney',
  'Australia/Canberra': 'Australia/Sydney',
  'Australia/Currie': 'Australia/Hobart',
  'Australia/LHI': 'Australia/Lord_Howe',
  'Australia/NSW': 'Australia/Sydney',
  'Australia/North': 'Australia/Darwin',
  'Australia/Queensland': 'Australia/Brisbane',
  'Australia/South': 'Australia/Adelaide',
  'Australia/Tasmania': 'Australia/Hobart',
  'Australia/Victoria': 'Australia/Melbourne',
  'Australia/West': 'Australia/Perth',
  'Australia/Yancowinna': 'Australia/Broken_Hill',
  'Brazil/Acre': 'America/Rio_Branco',
  'Brazil/DeNoronha': 'America/Noronha',
  'Brazil/East': 'America/Sao_Paulo',
  'Brazil/West': 'America/Manaus',
  'Canada/Atlantic': 'America/Halifax',
  'Canada/Central': 'America/Winnipeg',
  'Canada/Eastern': 'America/Toronto',
  'Canada/Mountain': 'America/Edmonton',
  'Canada/Newfoundland': 'America/St_Johns',
  'Canada/Pacific': 'America/Vancouver',
  'Canada/Saskatchewan': 'America/Regina',
  'Canada/Yukon': 'America/Whitehorse',
  'Chile/Continental': 'America/Santiago',
  'Chile/EasterIsland': 'Pacific/Easter',
  Cuba: 'America/Havana',
  Egypt: 'Africa/Cairo',
  Eire: 'Europe/Dublin',
  'Etc/UCT': 'Etc/UTC',
  'Europe/Belfast': 'Europe/London',
  'Europe/Tiraspol': 'Europe/Chisinau',
  GB: 'Europe/London',
  'GB-Eire': 'Europe/London',
  'GMT+0': 'Etc/GMT',
  'GMT-0': 'Etc/GMT',
  GMT0: 'Etc/GMT',
  Greenwich: 'Etc/GMT',
  Hongkong: 'Asia/Hong_Kong',
  Iceland: 'Atlantic/Reykjavik',
  Iran: 'Asia/Tehran',
  Israel: 'Asia/Jerusalem',
  Jamaica: 'America/Jamaica',
  Japan: 'Asia/Tokyo',
  Kwajalein: 'Pacific/Kwajalein',
  Libya: 'Africa/Tripoli',
  'Mexico/BajaNorte': 'America/Tijuana',
  'Mexico/BajaSur': 'America/Mazatlan',
  'Mexico/General': 'America/Mexico_City',
  NZ: 'Pacific/Auckland',
  'NZ-CHAT': 'Pacific/Chatham',
  Navajo: 'America/Denver',
  PRC: 'Asia/Shanghai',
  'Pacific/Johnston': 'Pacific/Honolulu',
  'Pacific/Ponape': 'Pacific/Pohnpei',
  'Pacific/Samoa': 'Pacific/Pago_Pago',
  'Pacific/Truk': 'Pacific/Chuuk',
  'Pacific/Yap': 'Pacific/Chuuk',
  Poland: 'Europe/Warsaw',
  Portugal: 'Europe/Lisbon',
  ROC: 'Asia/Taipei',
  ROK: 'Asia/Seoul',
  Singapore: 'Asia/Singapore',
  Turkey: 'Europe/Istanbul',
  UCT: 'Etc/UTC',
  'US/Alaska': 'America/Anchorage',
  'US/Aleutian': 'America/Adak',
  'US/Arizona': 'America/Phoenix',
  'US/Central': 'America/Chicago',
  'US/East-Indiana': 'America/Indiana/Indianapolis',
  'US/Eastern': 'America/New_York',
  'US/Hawaii': 'Pacific/Honolulu',
  'US/Indiana-Starke': 'America/Indiana/Knox',
  'US/Michigan': 'America/Detroit',
  'US/Mountain': 'America/Denver',
  'US/Pacific': 'America/Los_Angeles',
  'US/Samoa': 'Pacific/Pago_Pago',
  UTC: 'Etc/UTC',
  Universal: 'Etc/UTC',
  'W-SU': 'Europe/Moscow',
  Zulu: 'Etc/UTC',
  // all ETC/GMT are changing the symbol, they are moved to a similar timezone
  HST: 'Pacific/Honolulu',
  MST: 'America/Phoenix',
  PST8PDT: 'America/Los_Angeles',
  MST7MDT: 'America/Denver',
  CST6CDT: 'America/Chicago',
  EST: 'America/Panama',
  EST5EDT: 'America/New_York',
  WET: 'Europe/Lisbon',
  CET: 'Europe/Brussels',
  MET: 'Europe/Brussels',
  EET: 'Europe/Athens',
  'Antarctica/McMurdo': 'Pacific/Auckland',
};

// https://datahub.io/core/country-list
export const countryToCountryCode = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'Andorra', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia, Plurinational State of', code: 'BO' },
  { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, the Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Curaçao', code: 'CW' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: "Côte d'Ivoire", code: 'CI' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Eswatini', code: 'SZ' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and McDonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People's Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People's Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, the Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montenegro', code: 'ME' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestine, State of', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'Rwanda', code: 'RW' },
  { name: 'Réunion', code: 'RE' },
  { name: 'Saint Barthélemy', code: 'BL' },
  { name: 'Saint Helena, Ascension and Tristan da Cunha', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Martin (French part)', code: 'MF' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia', code: 'RS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Sint Maarten (Dutch part)', code: 'SX' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'South Sudan', code: 'SS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela, Bolivarian Republic of', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
  { name: 'Åland Islands', code: 'AX' },
];
