export const isSettingsPagePath = (path: string) => {
  return path.startsWith('/settings/');
};

export enum SharedRoutes {
  LOGIN = '/login',
  LOGIN_AUTH0 = '/login/auth0',
  LOGIN_DIALPAD = '/login/dialpad',
  DIALPAD_AUTH_CALLBACK = '/login/dialpad/callback',
}

export enum ManagerRoutes {
  HOME = '/home',

  FORECASTS = '/forecasts/forecast',
  FORECASTS_PLAN = '/forecasts/plan',
  FORECASTS_TICKET_GROUPS = '/forecasts/ticket-groups',

  REAL_TIME = '/real-time',

  REPORTS_DEFAULT = '/reporting',
  REPORTS_PERFORMANCE = '/reporting/performance',
  REPORTS_INSIGHTS_DEFAULT = '/reporting/insights',
  REPORTS_INSIGHTS = '/reporting/insights/:timeUnit?',
  REPORTS_TIMESHEETS = '/reporting/timesheets',

  REQUESTS_INBOX = '/requests',

  SETTINGS_MANAGER_ACCESS = '/settings/managers',
  SETTINGS_TEAM_SURFERS = '/settings/surfers-teams',
  SETTINGS_CONNECTIONS = '/settings/connections',
  SETTINGS_TIME_OFF = '/settings/time-off',
  SETTINGS_BILLING = '/settings/billing',
  SETTINGS_COMMUNICATION = '/settings/communication',

  SCHEDULE = '/schedule',
  SCHEDULE_SHIFTS = '/schedule/shifts',
  SCHEDULE_ACTIVITIES = '/schedule/activities',
  SCHEDULE_ACTIVITIES_PLANS = '/schedule/activities/plans',
  SCHEDULE_ACTIVITIES_PLANS_EDIT = '/schedule/activities/plans/:taskTemplateId',

  SHIFT_PLANS = '/shift-plans',
  SHIFT_PLAN = '/shift-plans/:planId',
  SHIFT_PLAN_PATTERNS = '/shift-plans/:planId/patterns',
}

export enum SurferRoutes {
  SHIFTS = '/shifts',
  SLACK_SUCCESS = '/slack-success',
  PERFORMANCE = '/performance',
  GOOGLE_AUTH_CALLBACK = '/google-oauth-callback',
}

export enum SurfboardRoutes {
  FEATURES = '/settings/features',
}
