import { z } from 'zod';

import { PersistedCustomTaskSchema } from './activity';
import { ConnectionProviderNameSchema } from './connection-providers';
import {
  AccountTypeSchema,
  LinkedAccountIdSchema,
  OrganizationId,
  OrganizationIdSchema,
  ProviderIdSchema,
  RemoteCommentIdSchema,
  RemoteTicketIdSchema,
  SnapshotIdSchema,
  SurferId,
  SurferIdSchema,
} from './ids';
import { BlockIdSchema, OriginSchema as ShiftOriginSchema } from './schedule';
import { DurationSchema, IntervalSchema, dateString } from './time';
import { RatioSchema } from './utils';

export const TicketStatusSchema = z.enum(['OPEN', 'CLOSED', 'ON_HOLD']); // TODO: we might see other (unsupported) statuses and we should log/alert if we do
export type TicketStatus = z.infer<typeof TicketStatusSchema>;

export const ExtendedTicketStatusSchema = TicketStatusSchema.or(
  z.literal('MERGE_NONSTANDARD_VALUE'),
);

const SnapshotSummarySchema = z.union([
  z.object({
    id: SnapshotIdSchema,
    type: z.literal('comment'),
    remoteId: RemoteCommentIdSchema.optional(),
  }),
  z.object({
    id: SnapshotIdSchema,
    type: z.literal('ticket'),
    status: TicketStatusSchema,
    remoteId: RemoteTicketIdSchema.optional(),
    remoteUrl: z.string().min(1).optional(), // not all providers support URLs
  }),
]);

export type SnapshotSummary = z.infer<typeof SnapshotSummarySchema>;

export const TicketSessionEventSchema = z.union([TicketStatusSchema, z.enum(['COMMENT'])]);
export type TicketSessionEvent = z.infer<typeof TicketSessionEventSchema>;

export const TicketSnapshotChannelTypeSchema = z.enum(['case', 'call', 'chat']);
export type TicketSnapshotChannelType = z.infer<typeof TicketSnapshotChannelTypeSchema>;

export const TicketSessionSchema = z.object({
  mergeTicketId: z.string().optional(),
  ticketId: z.string().optional(),
  ticketUrl: z.string().optional(),
  ticketChannelType: TicketSnapshotChannelTypeSchema.optional(),
  interval: IntervalSchema,
  events: z
    .object({
      type: TicketSessionEventSchema,
      timestamp: dateString,
      isOffTask: z.boolean().optional(),
    })
    .array(),
});
export type TicketSession = z.infer<typeof TicketSessionSchema>;

export const TicketSessionWithProviderSchema = TicketSessionSchema.extend({
  provider: z.object({
    id: ProviderIdSchema,
    name: ConnectionProviderNameSchema,
    displayName: z.string(),
  }),
});
export type TicketSessionWithProvider = z.infer<typeof TicketSessionWithProviderSchema>;

const BaseBlockSchema = z.object({
  interval: IntervalSchema,
  // references the snapshots that form this block
  snapshots: z.preprocess(v => v ?? [], SnapshotSummarySchema.array()),
  ticketSessions: TicketSessionSchema.array().optional(),
});

const TimeOnTaskBlockSchema = BaseBlockSchema.extend({
  type: z.literal('time-on-task'),
  providerId: ProviderIdSchema,
});

const TimeOffTaskBlockSchema = BaseBlockSchema.extend({
  type: z.literal('time-off-task'),
  actualProviderId: ProviderIdSchema,
  // ^ field named actualProviderId because we used to also include expectedProviderIds
  // because that used to be the only way we created off-task blocks (wrong provider)
  // we removed expectedProviderIds when we added channel-level adherence
  // because now there are multiple reasons why a block might be off-task
  // and with ticket-level adherence in the future there are even more possible reasons
});

export const InferredBlockSchema = z.union([TimeOnTaskBlockSchema, TimeOffTaskBlockSchema]);
export type InferredBlock = z.infer<typeof InferredBlockSchema>;

/**
 * Schema for the response of the endpoint /timeline used to fetch a single timeline.
 */
export const GetPerformanceTimelineRespSchema = z.object({
  /**
   * All the activity block inferred from the surfer actions.
   */
  blocks: InferredBlockSchema.array(),
  /**
   * When the timeline was updated the last time.
   */
  updatedAt: dateString.optional(),
});
export type GetPerformanceTimelineResp = z.infer<typeof GetPerformanceTimelineRespSchema>;

export const ShiftTimeOnTaskSchema = z.object({
  shiftBlock: z.object({
    id: BlockIdSchema,
    origin: ShiftOriginSchema,
    interval: IntervalSchema,
    surferId: SurferIdSchema,
  }),
  timeOnTask: RatioSchema.nullable(), // null if no trackable tasks in shift
  onTaskTotalTime: DurationSchema.nullable(),
  scheduledTotalTime: DurationSchema.nullable(),
  /**
   * When the contributing timelines were updated the last time.
   */
  updatedAt: dateString.optional(),
});
export type ShiftTimeOnTask = z.infer<typeof ShiftTimeOnTaskSchema>;

export const GetTimeOnTaskRespSchema = z.object({
  // TODO this should be paginated because we might return a massive array if we request a big interval for a big team
  shifts: ShiftTimeOnTaskSchema.array(),
});
export type GetTimeOnTaskResp = z.infer<typeof GetTimeOnTaskRespSchema>;

export const TimeOnTaskStatusTypeSchema = z.union([
  z.literal('time-on-task'),
  z.literal('time-inactive'),
  z.literal('time-wrong-task'),
  z.literal('time-untracked'),
  z.literal('time-off-shift'),
]);
export type TimeOnTaskStatusLabel = z.infer<typeof TimeOnTaskStatusTypeSchema>;

export const TimeOnTaskSurferStatusSchema = z.object({
  type: TimeOnTaskStatusTypeSchema,
  updatedAt: dateString.optional(), // not implemented yet
  statusChangedAt: dateString.optional(),
  ticketSessions: z.optional(z.array(TicketSessionWithProviderSchema)),
});
export type TimeOnTaskSurferStatus = z.infer<typeof TimeOnTaskSurferStatusSchema>;

export const GetTimeOnTaskStatusesRespSchema = z.record(TimeOnTaskSurferStatusSchema);
export type GetTimeOnTaskStatusesResp = z.infer<typeof GetTimeOnTaskStatusesRespSchema>;

export const GetConnectionHistoryRespSchema = z.object({
  connections: z
    .object({ providerId: ProviderIdSchema, start: dateString, end: dateString.optional() })
    .array(),
});
export type GetConnectionHistoryResp = z.infer<typeof GetConnectionHistoryRespSchema>;
export type ConnectionHistory = GetConnectionHistoryResp['connections'];

export const LinkedAccountSchema = z.object({
  providerId: ProviderIdSchema,
  type: AccountTypeSchema,
  /**
   * account token used to interact with the API
   */
  token: z.string(),
  /**
   * name of the service (aka provider) this account is connected to (i.e. zendesk, aircall, intercom)
   */
  integration: z.string(),
  /**
   * unique identifier for a linked account as provided by Merge
   */
  id: LinkedAccountIdSchema,
  organizationId: OrganizationIdSchema,
  createdAt: dateString,
  deletedAt: dateString.nullable(),
});

export type LinkedAccount = z.infer<typeof LinkedAccountSchema>;

export const GetLinkedAccountsRespSchema = z.object({
  results: z
    .object({
      id: LinkedAccountIdSchema,
      providerId: ProviderIdSchema,
      createdAt: dateString,
      deletedAt: dateString.nullable().optional(),
    })
    .array(),
});
export type GetLinkedAccountsResp = z.infer<typeof GetLinkedAccountsRespSchema>;

export const GetLinkTokenRespSchema = z.object({
  linkToken: z.string(),
});

export type GetLinkTokenResp = z.infer<typeof GetLinkTokenRespSchema>;

export const LinkedAccountTokenExchangeRequestSchema = z.object({
  providerId: ProviderIdSchema,
  /**
   * public token that needs to be exchanged
   */
  token: z.string(),
  type: AccountTypeSchema,
});

export type LinkedAccountTokenExchangeRequest = z.infer<
  typeof LinkedAccountTokenExchangeRequestSchema
>;

export const LinkedAccountTokenExchangeResponseSchema = z.object({
  success: z.boolean(),
});

export type LinkedAccountTokenExchangeResponse = z.infer<
  typeof LinkedAccountTokenExchangeResponseSchema
>;

export const ClosedTicketSchema = z.object({
  id: z.number(),
  surferId: SurferIdSchema,
  remoteId: z.string(),
  remoteUrl: z.string().nullable(),
  timestamp: dateString,
});
export type ClosedTicket = z.infer<typeof ClosedTicketSchema>;

const PerformanceMetricsForSurferSchema = z.object({
  surferId: z.number(),
  closedTicketsPerServiceHour: z.number().nullable(),
  closedTicketsPerHour: z.number().nullable(),
  utilisation: z.number().nullable(),
  occupancy: z.number().nullable(),
  closedTicketSnapshots: ClosedTicketSchema.array(),
});

export const PerformanceMetricsForSurfersSchema = z.array(PerformanceMetricsForSurferSchema);

export type PerformanceMetricsForSurfers = z.infer<typeof PerformanceMetricsForSurfersSchema>;

const PerformanceMetricsTotalsSchema = z.object({
  closedTickets: z.number(),
  closedTicketsPerHour: z.number().nullable(),
  closedTicketsPerServiceHour: z.number().nullable(),
  scheduledTime: z.number(),
  utilisation: z.object({ percentage: z.number(), hoursDiff: z.number() }).nullable(),
  occupancy: z.object({ percentage: z.number(), hoursDiff: z.number() }).nullable(),
  timeOnTask: z.object({ percentage: z.number(), hoursDiff: z.number() }).nullable(),
});

export const TeamPerformanceMetricsTotalsSchema = PerformanceMetricsTotalsSchema.extend({
  surfers: z.number(),
});
export type TeamPerformanceMetricsTotals = z.infer<typeof TeamPerformanceMetricsTotalsSchema>;

export const TeamPerformanceMetricsWithTotalsSchema = z.object({
  surfersWithPerformanceMetrics: PerformanceMetricsForSurfersSchema,
  totals: TeamPerformanceMetricsTotalsSchema,
});

export type TeamPerformanceMetricsWithTotals = z.infer<
  typeof TeamPerformanceMetricsWithTotalsSchema
>;

const SurferPerformanceMetricsSchema = z.object({
  surferId: z.number(),
  scheduledTime: z.number(),
  closedTicketSnapshots: ClosedTicketSchema.array(),
  closedTicketsPerServiceHour: z.number().nullable(),
  closedTicketsPerHour: z.number().nullable(),
  utilisation: z.number().nullable(),
  occupancy: z.number().nullable(),
  timeOnTask: z.number().nullable(),
});

const LeanSurferPerformanceMetricsSchema = SurferPerformanceMetricsSchema.omit({
  closedTicketSnapshots: true,
}).extend({
  closedTicketSnapshotCount: z.number(),
  surferName: z.string(),
  surferEmail: z.string(),
});

export const SurfersPerformanceMetricsSchema = z.object({
  rows: SurferPerformanceMetricsSchema.array(),
  totals: TeamPerformanceMetricsTotalsSchema,
});

export const SurferMetricsCsvRowSchema = z.object({
  agent: z.string(),
  'agent email': z.string(),
  date: z.string(),
  'scheduled hours': z.string(),
  utilisation: z.string(),
  'total conversations closed': z.number(),
  'conversations closed per hour': z.string(),
  'conversations closed per service hour': z.string(),
  'time on task': z.string(),
  occupancy: z.string(),
});

export const SurferMetricsCsvSchema = z.array(SurferMetricsCsvRowSchema);
export type SurferMetricsCsv = z.infer<typeof SurferMetricsCsvSchema>;

export const TaskMetricsCsvRowSchema = z.object({
  activity: z.string(),
  'activity type': z.string(),
  'scheduled hours': z.string(),
  'conversations commented on': z.number(),
  'conversations opened': z.number(),
  'conversations on hold': z.number(),
  'conversations closed': z.number(),
  'wrong task snapshots': z.number(),
  date: z.string(),
  'closed per hour': z.string(),
  'time on task': z.string(),
  'average interaction time': z.number(),
  'average conversation time': z.number(),
});

export const TaskMetricsCsvSchema = z.array(TaskMetricsCsvRowSchema);
export const ShiftMetricsCsvSchema = z.array(TaskMetricsCsvRowSchema.omit({ date: true }));
export type TaskMetricsCsv = z.infer<typeof TaskMetricsCsvSchema>;
export type ShiftMetricsCsv = z.infer<typeof ShiftMetricsCsvSchema>;

// Without closedTicketSnapshots as they are too many and not needed always
export const LeanSurfersPerformanceTableMetricsSchema = z.object({
  surfers: LeanSurferPerformanceMetricsSchema.array(),
  date: z.string(),
  totals: TeamPerformanceMetricsTotalsSchema,
});

export type LeanSurfersPerformanceTableMetrics = z.infer<
  typeof LeanSurfersPerformanceTableMetricsSchema
>;

export type SurfersPerformanceTableMetrics = z.infer<typeof SurfersPerformanceMetricsSchema>;

export const SnapshotSummariesSchema = z.array(SnapshotSummarySchema);
export type SnapshotSummaries = z.infer<typeof SnapshotSummariesSchema>;

export const TaskPerformanceMetricsSchema = z.object({
  task: PersistedCustomTaskSchema,
  scheduledHours: z.object({ value: z.number(), percentage: z.number() }),
  averageInteractionTimeSeconds: z.number().optional(),
  averageConversationTimeSeconds: z.number().optional(),
  closedSnapshots: SnapshotSummariesSchema,
  onHoldSnapshots: SnapshotSummariesSchema,
  openedSnapshots: SnapshotSummariesSchema,
  commentSnapshots: SnapshotSummariesSchema,
  wrongTaskSnapshots: SnapshotSummariesSchema,
  closedPerHour: z.number().nullable(),
  timeOnTask: z.number().nullable(),
});

export const LeanTaskPerformanceMetricsSchema = TaskPerformanceMetricsSchema.omit({
  closedSnapshots: true,
  onHoldSnapshots: true,
  openedSnapshots: true,
  commentSnapshots: true,
  wrongTaskSnapshots: true,
}).extend({
  closedSnapshotsCount: z.number(),
  onHoldSnapshotsCount: z.number(),
  openedSnapshotsCount: z.number(),
  commentSnapshotsCount: z.number(),
  wrongTaskSnapshotsCount: z.number(),
});

export const MetricsByAgentSchema = z.object({
  agent: z.string(),
  agentEmail: z.string(),
  data: z.array(
    z.object({
      date: z.string(),
      scheduledHours: z.number(),
      utilisation: z.string(),
      totalConversationsClosed: z.number(),
      conversationsClosedPerHour: z.number(),
      conversationsClosedPerServiceHour: z.number(),
      timeOnTask: z.string(),
      occupancy: z.string(),
    }),
  ),
});
export type MetricsByAgent = z.infer<typeof MetricsByAgentSchema>;

export const MetricsByActivitySchema = z.object({
  activity: z.string(),
  activityType: z.string(),
  data: z.array(
    z.object({
      date: z.string(),
      scheduledHours: z.number(),
      timeOnTask: z.string(),
      conversationsOpened: z.number(),
      conversationsCommentedOn: z.number(),
      conversationsOnHold: z.number(),
      conversationsClosed: z.number(),
      wrongTaskSnapshots: z.number(),
      averageInteractionTime: z.number(),
      averageConversationTime: z.number(),
      closedPerHour: z.number(),
    }),
  ),
});
export type MetricsByActivity = z.infer<typeof MetricsByActivitySchema>;

export const DailyLeanTaskPerformanceMetricsSchema = LeanTaskPerformanceMetricsSchema.extend({
  date: z.string(),
});

export type DailyLeanTaskPerformanceMetrics = z.infer<typeof DailyLeanTaskPerformanceMetricsSchema>;

export const TaskPerformanceMetricsPerDaySchema = TaskPerformanceMetricsSchema.extend({
  date: z.string(),
});

export type TaskPerformanceMetricsPerDay = z.infer<typeof TaskPerformanceMetricsPerDaySchema>;

export type TaskPerformanceMetrics = z.infer<typeof TaskPerformanceMetricsSchema>;

export const SurferPerformanceMetricsWithTotalsSchema = z.object({
  totals: PerformanceMetricsTotalsSchema,
  performanceMetricsPerTask: z.array(TaskPerformanceMetricsSchema),
});

export type SurferPerformanceMetricsWithTotals = z.infer<
  typeof SurferPerformanceMetricsWithTotalsSchema
>;

export const ShiftsPerformanceMetricsSchema = z.object({
  rows: TaskPerformanceMetricsSchema.array(),
  totals: TeamPerformanceMetricsTotalsSchema,
});
export type ShiftsPerformanceMetrics = z.infer<typeof ShiftsPerformanceMetricsSchema>;

export const PerformanceTimelineSchema = z
  .object({
    // snake_case from db
    id: z.number(),
    start: z.date(),
    end: z.date(),
    organization_id: z.number(),
    surfer_id: z.number(),
    inferred_blocks: InferredBlockSchema.array(),
    created_at: z.date(),
  })
  .transform(snake => {
    return {
      id: snake.id,
      start: snake.start,
      end: snake.end,
      organizationId: snake.organization_id as OrganizationId,
      surferId: snake.surfer_id as SurferId,
      inferredBlocks: snake.inferred_blocks,
      createdAt: snake.created_at,
    };
  });

export type PerformanceTimeline = z.infer<typeof PerformanceTimelineSchema>;

export interface SurfersPerformanceMetrics {
  surferData: {
    surferId: SurferId;
    scheduledTime: number;
    closedTicketSnapshots: ClosedTicket[];
    closedTicketsPerServiceHour: number | null;
    closedTicketsPerHour: number | null;
    utilisation: number | null;
    occupancy: number | null;
    timeOnTask: number | null;
  }[];
  totals: {
    closedTickets: number;
    closedTicketsPerHour: number | null;
    closedTicketsPerServiceHour: number | null;
    scheduledTime: number;
    scheduledServiceTime: number;
    utilisation: number | null;
    occupancy: number | null;
    timeOnTask: number | null;
  };
}

// Agent status timeline

export const AgentStatusBlockSchema = z.object({
  start: dateString,
  end: dateString,
  status: z.string(),
  reason: z.string().nullable(),
  contactCenterIds: z.array(z.string()),
});

export type AgentStatusBlock = z.infer<typeof AgentStatusBlockSchema>;

export const AgentStatusTimelineSchema = z.object({
  surferId: SurferIdSchema,
  organizationId: OrganizationIdSchema,
  blocks: z.array(AgentStatusBlockSchema),
  start: dateString,
  end: dateString,
  createdAt: dateString.optional(),
});

export type AgentStatusTimeline = z.infer<typeof AgentStatusTimelineSchema>;

export const AgentStatusTimelineRespSchema = z.object({
  blocks: z.array(AgentStatusBlockSchema),
  updatedAt: dateString.optional(),
});

export type AgentStatusTimelineResp = z.infer<typeof AgentStatusTimelineRespSchema>;

export const correctAgentStatuses = ['available', 'unavailable', 'occupied', 'wrapup', 'busy'];
