import { z } from 'zod';

import { SurferIdSchema, TeamIdSchema } from './ids';

export const SurferSelectionSchema = z.object({
  selectedSurferIds: z.array(SurferIdSchema),
  selectedTeamIds: z.array(TeamIdSchema),
});

export type SurferSelection = z.infer<typeof SurferSelectionSchema>;
