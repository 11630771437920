import { z } from 'zod';

import { dateString } from './time';

export const CalendarSchema = z.object({
  name: z.string().min(1, { message: 'Enter calendar name' }),
  link: z.string().min(1, { message: 'Enter iCal link' }),
  keywords: z.array(z.string()).optional().nullable(),
  lastFetchedAt: dateString.nullish(),
  errorMessage: z.string().nullish(),
  createdAt: dateString.nullish(),
});
export type Calendar = z.infer<typeof CalendarSchema>;

const PersistedCalendarSchema = CalendarSchema.extend({
  id: z.number(),
});
export type PersistedCalendar = z.infer<typeof PersistedCalendarSchema>;

export const GetCalendarsRespSchema = z.array(PersistedCalendarSchema);
export type GetCalendarsResp = z.infer<typeof GetCalendarsRespSchema>;

export const InvalidLinkResponseSchema = z.object({
  message: z.literal('Invalid calendar link'),
});
export type InvalidLinkResponse = z.infer<typeof InvalidLinkResponseSchema>;

export const UpdateCalendarsReqSchema = z.array(CalendarSchema);
export type UpdateCalendarsReqBody = z.infer<typeof UpdateCalendarsReqSchema>;

export const CreateCalendarReqSchema = CalendarSchema;
export type CreateCalendarReq = z.infer<typeof CreateCalendarReqSchema>;

export const UpdateCalendarReqSchema = PersistedCalendarSchema;
export type UpdateCalendarReq = z.infer<typeof UpdateCalendarReqSchema>;
