import { z } from 'zod';

import { StreamIdSchema, SurferIdSchema } from './ids';
import { IntervalSchema, dateString } from './time';
import { booleanString, fromUrlParamArray, nonnegativeInteger } from './utils';

const MetricsGroupedByChannelSchema = z.object({
  date: dateString,
  channelName: z.string().min(1),
  value: nonnegativeInteger,
  streamId: StreamIdSchema,
});

export type StaffingMetric = z.infer<typeof MetricsGroupedByChannelSchema>;

export const GetStaffingRespSchema = MetricsGroupedByChannelSchema.array();

// because is a GET we need to transform all the strins url params
export const CoverageQuerySchema = z
  .object({
    start: dateString,
    end: dateString,
    increment: z.union([z.literal('hour'), z.literal('half-hour'), z.literal('day')]),
    selectedTicketGroups: fromUrlParamArray(StreamIdSchema).optional(),
    selectedSurfers: fromUrlParamArray(SurferIdSchema).optional(),
    published: booleanString,
  })
  .refine(interval => interval.start.valueOf() <= interval.end.valueOf());

export type GetCoverageQuery = z.infer<typeof CoverageQuerySchema>;

const CoverageItemSchema = z.object({
  interval: IntervalSchema,
  coverage: z.object({
    required: z.number(),
    actual: z.number(),
    surplus: z.number(),
  }),
});

export const CoverageBarResponseSchema = z.array(CoverageItemSchema);

export type CoverageBarResponse = z.infer<typeof CoverageBarResponseSchema>;
