import * as Sentry from '@sentry/react';
import { QueryClientConfig, QueryOptions } from '@tanstack/react-query';
import { toCamel } from 'convert-keys';
import { useHistory } from 'react-router-dom';
import { PagedResults, TeamId, TeamIdSchema, pagedResultsSchema } from 'shared-types';
import { monotonicFactory } from 'ulidx';
import { z } from 'zod';

import { useIsSurfboardEmployee } from '../hooks/useIsSurfboardEmployee';
import { useManagerId } from '../hooks/useManagerId';
import { useSetShouldHardNavigate } from '../hooks/useNavigationConfig';
import { useOrgId } from '../hooks/useOrgId';
import { useRoles } from '../hooks/useRoles';
import { useSurferId } from '../hooks/useSurferId';
import { useAuth } from '../providers/AuthProvider';
import { SESSION_ID } from '../session';
import { logError } from '../utils/logger';
import { STANDARD_STALE_TIME } from './constants';

const generateRequestId = monotonicFactory();

const reloadIfBundleIsStale = (response: Response, setShouldHardNavigate: (v: boolean) => void) => {
  const serverCommitHash = response.headers.get('commit-hash');
  const serverHasDifferentCommitHash =
    import.meta.env.MODE !== 'staging' &&
    serverCommitHash != null &&
    serverCommitHash !== __COMMIT_HASH__;

  if (serverHasDifferentCommitHash) {
    if ([400, 500].includes(response.status)) {
      window.location.reload();
    } else {
      setShouldHardNavigate(true);
    }
  }
};

type FetchOptions =
  | {
      method: 'GET';
    }
  | {
      method: 'POST' | 'PATCH' | 'PUT' | 'DELETE';
      body?: string;
    };

type RequestOptions = FetchOptions & {
  version?: 'v0' | 'v1' | 'v2';
  useExactPathProvided?: boolean;
  searchParams?: Record<string, string>;
  transformKeys?: boolean;
};

type PaginatedRequestOptions = FetchOptions & {
  version?: 'v0' | 'v1' | 'v2';
  useExactPathProvided?: boolean;
  searchParams?: Record<string, string | undefined>;
  transformKeys?: boolean;
  offsetUrl?: string | null;
};

type Unpaginated<MaybePaginated> =
  MaybePaginated extends PagedResults<infer T> ? T[] : MaybePaginated;

const isPaginated = <T>(input: unknown): input is PagedResults<T> => {
  return pagedResultsSchema(z.unknown()).safeParse(input).success;
};

export class ServerSideError extends Error {
  readonly _tag = 'ServerSideError';

  constructor(message: string) {
    super(message);
  }
}

export class BadRequestError extends Error {
  readonly _tag = 'BadRequestError';

  statusCode: number;
  responseBody: unknown;

  constructor(message: string, statusCode: number, responseBody: unknown) {
    super(message);
    this.statusCode = statusCode;
    this.responseBody = responseBody;
  }
}
export const isBadRequestError = (error: unknown) => error instanceof BadRequestError;

export class UnauthorizedError extends Error {
  constructor(
    public error: string,
    public error_description?: string,
  ) {
    super(error_description || error);
    this.name = 'UnauthorizedError';
  }
}

/**
 * Represents when a request fails because the user making the request does not have the correct
 * team permissions
 */
export class ForbiddenError extends Error {
  readonly statusCode = 403;
  readonly name = 'ForbiddenError';

  constructor(public readonly unauthorisedTeams: { id: TeamId; name: string }[]) {
    super('Manager does not have required permissions to edit these teams');
  }
}

export const isConflictedResourceError = (e: unknown): boolean => {
  return e instanceof BadRequestError && e.statusCode === 409;
};

export class SurfboardEmployeeWrongOrgError extends Error {
  readonly _tag = 'SurfboardEmployeeWrongOrgError';

  statusCode: number;

  constructor() {
    super('Surfboard employee logged into wrong org');
  }
}

export class NotFoundError extends Error {
  readonly _tag = 'NotFoundError';

  statusCode: number;

  constructor() {
    super('404 not found error');
  }
}

type RetryOptions = Pick<QueryOptions, 'retry' | 'retryDelay'>;

const retryOptions: RetryOptions = {
  retry: (failureCount, error) => {
    // return value determines whether react query retries again
    if (error instanceof BadRequestError || error instanceof ServerSideError) {
      return false;
    }
    return failureCount < 2;
  },
};

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      ...retryOptions,
      staleTime: STANDARD_STALE_TIME,
    },
  },
};

export const constructServerUrl = (path: string): URL => {
  const host = import.meta.env.VITE_SERVER_HOST ?? location.host;
  return new URL(path, `${location.protocol}//${host}`);
};

// A mostly identical version of useRequest, with the exception that
// now we expect a paginated response, instead of looping over the pages and combining
// results.
export const usePaginatedRequest = () => {
  const orgId = useOrgId();
  const surferId = useSurferId();
  const managerId = useManagerId();
  const isSurfboardEmployee = useIsSurfboardEmployee();
  const roles = useRoles();
  const history = useHistory();
  const _debugInfo = { orgId, surferId, managerId, isSurfboardEmployee, roles };
  const {
    authMethod,
    isAuthenticated,
    auth0: { getAccessTokenSilently },
    logout,
  } = useAuth();
  const setShouldHardNavigate = useSetShouldHardNavigate();

  const getHeaders = async (): Promise<Record<string, string>> => {
    return {
      'Content-type': 'application/json',
      ...(authMethod === 'auth0' && {
        Authorization: isAuthenticated ? `Bearer ${await getAccessTokenSilently()}` : '',
      }),
      'x-commit-hash': __COMMIT_HASH__,
      'x-session-id': SESSION_ID,
    };
  };

  const handleErrors = async (
    response: Response,
    path: string,
    requestId: string,
  ): Promise<void> => {
    if (!response.ok) {
      const error = await errorFromResponse(response, { path, isSurfboardEmployee });
      if (error instanceof UnauthorizedError) {
        await logout().then(() => history.push('/'));
      }
      if (shouldAlertForError(error)) {
        logError(error, {
          extra: {
            path,
            requestId,
            statusCode: response.status,
            ..._debugInfo,
          },
        });
      }
      throw error; // needs to be thrown for tanstack-query to register there was an error
    }
  };

  const handleResponse = async <T extends z.ZodTypeAny>(
    response: Response,
    schema: T,
    path: string,
    requestId: string,
    responseRaw: string,
    version: string,
    transformKeys?: boolean,
  ) => {
    let jsonParsingError: unknown;
    let data: any;

    try {
      data = JSON.parse(responseRaw);
      if (transformKeys ?? version !== 'v0') {
        data = toCamel(data);
      }
    } catch (err) {
      jsonParsingError = err;
    }

    const result = schema.safeParse(data);
    if (!result.success) {
      let error = new Error('Client-side zod validation error');
      if (JSON.stringify(data) === '{}') {
        // Treating this validation error differently so we can silence it in Sentry
        error = new Error(
          `Weird error where the body received is empty. We don't know why, but doesn't seem to affect customers.`,
        );
      }
      logError(error, {
        extra: {
          ..._debugInfo,
          validationErrors: JSON.stringify(result.error.errors, null, 2),
          jsonParsingError,
          data,
          statusCode: response.status,
          contentType: response.headers.get('content-type'),
          path,
          requestId,
          responseRaw,
        },
      });
      throw error;
    }

    return result.data;
  };

  const fetchPaginatedResults = async <T extends z.ZodTypeAny>(
    url: string,
    headers: Record<string, string>,
    fetchOptions: PaginatedRequestOptions,
    schema: T,
    path: string,
    version: string,
  ) => {
    const requestId = generateRequestId();
    const response: Response = await fetch(url, {
      headers: { ...headers, 'x-request-id': requestId },
      ...fetchOptions,
    });

    reloadIfBundleIsStale(response, setShouldHardNavigate);
    await handleErrors(response, path, requestId);

    const responseRaw = await response.text();
    const parsed = await handleResponse(
      response,
      schema,
      path,
      requestId,
      responseRaw,
      version,
      fetchOptions.transformKeys,
    );

    if (!isPaginated<T>(parsed)) {
      // Validate that we get a paginated response from the server
      throw new Error(`Expected paginated response, got something else`);
    }

    return parsed as PagedResults<T>;
  };

  return async <T extends z.ZodTypeAny>(
    path: string,
    schema: T,
    options?: PaginatedRequestOptions,
  ): Promise<PagedResults<z.output<T>>> => {
    const {
      version = 'v0',
      searchParams,
      useExactPathProvided,
      offsetUrl,
      ...fetchOptions
    } = options ?? { method: 'GET' };
    const headers = await getHeaders();

    // the server returns us the next page URL by default,
    // if we have it, just invoke that URL directly
    // see serializePagination util
    if (offsetUrl)
      return fetchPaginatedResults(offsetUrl, headers, fetchOptions, schema, path, version);

    const initialUrl = constructServerUrl(
      useExactPathProvided ? path : `/${version}/organizations/${orgId}${path}`,
    );

    if (searchParams) {
      Object.entries(searchParams).forEach(([key, val]) => {
        if (val !== undefined) {
          initialUrl.searchParams.set(key, val);
        }
      });
    }

    return fetchPaginatedResults(
      initialUrl.toString(),
      headers,
      fetchOptions,
      schema,
      path,
      version,
    );
  };
};

export const useRequest = () => {
  // this needs to return a closure to prevent rules of hooks errors
  // because useOrgId and useAuth can't be called inside useQuery/useMutation
  const orgId = useOrgId();
  const surferId = useSurferId();
  const managerId = useManagerId();
  const isSurfboardEmployee = useIsSurfboardEmployee();
  const roles = useRoles();
  const history = useHistory();
  const _debugInfo = { orgId, surferId, managerId, isSurfboardEmployee, roles };
  const {
    authMethod,
    isAuthenticated,
    auth0: { getAccessTokenSilently },
    logout,
  } = useAuth();
  const setShouldHardNavigate = useSetShouldHardNavigate();

  const getHeaders = async (): Promise<Record<string, string>> => {
    return {
      'Content-type': 'application/json',
      ...(authMethod === 'auth0' && {
        Authorization: isAuthenticated ? `Bearer ${await getAccessTokenSilently()}` : '',
      }),
      'x-commit-hash': __COMMIT_HASH__,
      'x-session-id': SESSION_ID,
    };
  };

  const handleErrors = async (
    response: Response,
    path: string,
    requestId: string,
  ): Promise<void> => {
    if (!response.ok) {
      const error = await errorFromResponse(response, { path, isSurfboardEmployee });
      if (error instanceof UnauthorizedError) {
        await logout().then(() => history.push('/'));
      }
      if (shouldAlertForError(error)) {
        logError(error, {
          extra: {
            path,
            requestId,
            statusCode: response.status,
            ..._debugInfo,
          },
        });
      }
      throw error; // needs to be thrown for tanstack-query to register there was an error
    }
  };

  const handleResponse = async <T extends z.ZodTypeAny>(
    response: Response,
    schema: T,
    path: string,
    requestId: string,
    responseRaw: string,
    version: string,
    transformKeys?: boolean,
  ) => {
    let jsonParsingError: unknown;
    let data: any;

    try {
      data = JSON.parse(responseRaw);
      if (transformKeys ?? version !== 'v0') {
        data = toCamel(data);
      }
    } catch (err) {
      jsonParsingError = err;
    }

    const result = schema.safeParse(data);
    if (!result.success) {
      let error = new Error('Client-side zod validation error');
      if (JSON.stringify(data) === '{}') {
        // Treating this validation error differently so we can silence it in Sentry
        error = new Error(
          `Weird error where the body received is empty. We don't know why, but doesn't seem to affect customers.`,
        );
      }
      Sentry.captureException(error, {
        extra: {
          ..._debugInfo,
          validationErrors: JSON.stringify(result.error.errors, null, 2),
          jsonParsingError,
          data,
          statusCode: response.status,
          contentType: response.headers.get('content-type'),
          path,
          requestId,
          responseRaw,
        },
      });
      throw error;
    }

    return result.data;
  };

  const fetchPaginated = async <T extends z.ZodTypeAny>(
    url: string,
    headers: Record<string, string>,
    fetchOptions: RequestOptions,
    schema: T,
    path: string,
    version: string,
  ) => {
    const paginatedResultAccumulator: unknown[] = [];
    let nextUrl: string | null = url;

    while (nextUrl) {
      const requestId = generateRequestId();
      const response: Response = await fetch(nextUrl, {
        headers: { ...headers, 'x-request-id': requestId },
        ...fetchOptions,
      });

      reloadIfBundleIsStale(response, setShouldHardNavigate);
      await handleErrors(response, path, requestId);

      const responseRaw = await response.text();
      const parsed = await handleResponse(
        response,
        schema,
        path,
        requestId,
        responseRaw,
        version,
        fetchOptions.transformKeys,
      );

      if (!isPaginated<T>(parsed)) {
        return parsed as Unpaginated<T>;
      }

      const {
        results,
        pageInfo: { next },
      } = parsed;
      paginatedResultAccumulator.push(...results);
      nextUrl = next;
    }

    return paginatedResultAccumulator as Unpaginated<T>;
  };

  return async <T extends z.ZodTypeAny>(
    path: string,
    schema: T,
    options?: RequestOptions,
  ): Promise<Unpaginated<z.output<T>>> => {
    const {
      version = 'v0',
      searchParams,
      useExactPathProvided,
      ...fetchOptions
    } = options ?? { method: 'GET' };
    const headers = await getHeaders();

    const initialUrl = constructServerUrl(
      useExactPathProvided ? path : `/${version}/organizations/${orgId}${path}`,
    );

    if (searchParams) {
      Object.entries(searchParams).forEach(([key, val]) => {
        initialUrl.searchParams.set(key, val);
      });
    }

    return fetchPaginated(initialUrl.toString(), headers, fetchOptions, schema, path, version);
  };
};

const ForbiddenResponseBodySchema = z.object({
  unauthorisedTeams: z.object({ id: TeamIdSchema, name: z.string() }).array(),
});

const errorFromResponse = async (
  response: Response,
  context: { path: string; isSurfboardEmployee: boolean },
): Promise<Error> => {
  const responseBody = await response.json().catch(_ => undefined);

  if (response.status >= 500) {
    if (responseBody?.error === 'APPLY_IN_PROGRESS') {
      return new ServerSideError('APPLY_IN_PROGRESS');
    }

    return new ServerSideError(`${response.status} response from ${context.path}`);
  }

  if (response.status === 403) {
    const maybeForbiddenResponseBody = ForbiddenResponseBodySchema.safeParse(responseBody);
    if (maybeForbiddenResponseBody.success) {
      return new ForbiddenError(maybeForbiddenResponseBody.data.unauthorisedTeams);
    }

    if (context.isSurfboardEmployee) {
      return new SurfboardEmployeeWrongOrgError();
    }
  }

  if (response.status === 404) {
    return new NotFoundError();
  }

  if (response.status === 401) {
    return new UnauthorizedError('401 unauthorised error');
  }

  if (response.status === 403) {
    return new ForbiddenError([]);
  }

  if (response.status >= 400) {
    const errorMessage: string =
      {
        403: '403 FORBIDDEN error - could be surfer requesting manager endpoint',
      }[response.status] ?? 'Bad request error';

    return new BadRequestError(errorMessage, response.status, responseBody);
  }

  return new Error('Network response was not ok');
};

const shouldAlertForError = (err: Error): boolean => {
  if (err instanceof BadRequestError && err.statusCode === 409) {
    // we expect 409 errors caused by two managers are editing the same thing
    return false;
  }

  if (err instanceof SurfboardEmployeeWrongOrgError) {
    // we expect to see these when Surfboard employees have different orgs in different tabs
    return false;
  }

  if (err instanceof ForbiddenError) {
    // 403s can be an acceptable response when trying to access/edit a resource we don't have access to.
    return false;
  }

  if (err instanceof NotFoundError) {
    // 404s can be an acceptable response when requesting a resource that doesn't exist (eg: getting surfer feedback for a given day)
    return false;
  }

  return true;
};
