import * as Sentry from '@sentry/react';
import type { CaptureContext, SeverityLevel } from '@sentry/types';

const isHerokuReviewApp = window.location.host.includes('herokuapp');
export const isSentryEnabled =
  (import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production') &&
  import.meta.env.DONT_USE_SENTRY !== 'true' &&
  !isHerokuReviewApp;
const isProduction = import.meta.env.MODE === 'production';

/**
 * Captures an exception event and sends it to Sentry if it is enabled.
 * Simply logs to console if it is not production.
 * @param exception The exception to capture.
 * @param hint Optional additional data to attach to the Sentry event.
 * @returns the id of the captured Sentry event.
 */
export const logError = (exception: Error | unknown, context?: CaptureContext) => {
  if (isSentryEnabled) {
    Sentry.captureException(exception, context);
  }
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.error(exception);
  }
};

/**
 * Captures a message event and sends it to Sentry if it is enabled.
 * Simply logs to console if it is not production.
 * @param message The message to capture.
 * @param captureContext Define the level of the message or pass in additional data to attach to the message.
 * @returns the id of the captured message.
 */
export const logMessage = (message: string, captureContext?: CaptureContext | SeverityLevel) => {
  if (isSentryEnabled) {
    Sentry.captureMessage(message, captureContext);
  }
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.info(message, captureContext);
  }
};
