import { z } from 'zod';

import { ShiftTemplateId, ShiftTemplateIdSchema, SurferId } from './ids';
import { BlockTypeSchema } from './schedule';
import { TimezoneIana } from './time';

export type TallyOptions = {
  startDate: Date;
  endDate: Date;
  surfers?: SurferId[];
  shifts?: ShiftTemplateId[];
  published?: boolean;
  timezone?: TimezoneIana;
};

const ShiftCountSchema = z.object({
  shiftTemplateId: ShiftTemplateIdSchema.nullable(),
  count: z.number(),
  type: BlockTypeSchema,
});
export type ShiftCount = z.infer<typeof ShiftCountSchema>;

const ShiftTallyDaySchema = z.object({
  shifts: z.array(ShiftCountSchema),
  date: z.string(),
});
export type ShiftTallyDay = z.infer<typeof ShiftTallyDaySchema>;

export const ShiftTallyRespSchema = z.object({
  tallies: z.array(ShiftTallyDaySchema),
});
export type ShiftTallyResponse = z.infer<typeof ShiftTallyRespSchema>;
